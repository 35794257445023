<template>
    <div class="btn-container">
        <v-btn color="success" @click="refresh">
            {{ translate("demo.refreshButton") }}
        </v-btn>
    </div>
</template>

<script>
export default {
  name: "demo",
  props: {
      host: {
          type: String,
      default: "https://dispatchweb.eureka-technology.fr",
      },
      login: {
          type: String,
      default: "css",
      },
      password: {
          type: String,
      default: "css",
      },
      license: {
          type: String,
      default: "FORMAPIWEB",
      },
    cssUrl: {
      type: String,
      default: null,
    },
  },
  data: () => ({
      webManagerRequest: "/webmanager/Authentication/SignIn"
    }),
  computed: {
    demoUrl() {
      return `${this.host}${
        this.webManagerRequest
      }?clientURL=dispatchweb&forcedCssPath=${encodeURIComponent(
        this.cssUrl
      )}&DisableSSL=1&license=${this.license}&login=${this.login}&password=${
        this.password
      }`;
    },
  },
  methods: {
    refresh() {
          this.$emit("get-demo-css");
    }
  },
  watch: {
    cssUrl(newVal) {
        if (newVal)
        {
            window.open(this.demoUrl, "_blank");
        }
    }
  }
};
</script>
<style>
.btn-container {
    display:flex;
    justify-content:flex-end;
}
</style>
